.chat-session-selected{
    background-color: #F0F0F0;
}

.chat-session-container:hover{
    background-color: #F0F0F0;
}

.session-list-container{
    overflow-y: auto;
}

.chatbot-markdown > ol, ul {
        list-style: disc;
        padding-left: 20px;
}

.chatbot-markdown code, kbd, samp, pre {
    font-family: 'Futura Std' !important;
}

.chatbot-help{
    border: 1px solid #319edd;
    border-radius: 8px;
}
